import {Level9BackwardMapping, Level9ForwardMapping} from "./level_mapping_helper";
// a copy of this file exist on backend as well, any change needed to be, should be reflected on both sides.

export function calculateLevel9Coverage(messages, mappings) {
    const unique_level9 = {};
    for(const message of messages) {
        const {level_9: level9, message: level7} = message;
        if(!unique_level9[level9]) unique_level9[level9] = {};
        if(!unique_level9[level9][level7]) {
            unique_level9[level9][level7] = 1;
        } else {
            unique_level9[level9][level7]++;
        }
    }
    const result = {data: []};
    let total = 0;
    for(const level9 in unique_level9) {
        const unique_count = Object.values(unique_level9[level9]).reduce((sum, count) => sum + count, 0);
        result.data.push({level9, count: unique_count});
        total += unique_count;
    }
    result.grand_total = {level9: 'Grand Total', count: total}
    return result;
}

export function calculateLevel8Coverage(level9_coverage, mappings, assumption = 2) {

    const result = {data: [], assumption1: assumption};

    for(const item of level9_coverage.data) {
        const {level9, count} = item;
        const {level8, level6} = Level9BackwardMapping(mappings, level9);
        const {mb_no} = Level9ForwardMapping(mappings, level9);
        const enough = count >= assumption;
        result.data.push({level9, matching: count, enough, mb_no, level8, level6});
    }

    return result;
}

export function calculateLevel7Coverage(level8_coverage, mappings, assumption1 = 20, assumption2 = 3) {

    const result = {data: [], assumption1, assumption2};

    const accmulated_level8 = {}; //contains actual count of level9 in level8 under current scenario

    for(const item of level8_coverage.data) {
        const {level8} = item;
        if(!accmulated_level8[level8]) accmulated_level8[level8] = 1;
        else accmulated_level8[level8] = accmulated_level8[level8] + 1;
    }

    const {level8_mappings} = mappings;
    let grand_count = 0, grand_total = 0;

    function calculateCoverage(count, total) {
        const coverage = Math.round(total ? (count/total * 100) : 0);
        const is_covered = coverage >= assumption1 || count >= assumption2;
        return {count, total, coverage, is_covered};
    }

    for(const level8 in accmulated_level8) {
        const count = accmulated_level8[level8];
        const total = level8_mappings[level8] ? level8_mappings[level8].length : 0;
        const {coverage} = calculateCoverage(count, total);
        const level8Entry = level8_coverage.data.find((item) => item.level8 === level8);
        const is_covered = level8Entry?.enough || false;
        result.data.push({level8, count, total, coverage, is_covered});
        //adding to grand values
        grand_count += count;
        grand_total += total;
    }
    //grand total calc
    const {coverage, is_covered} = calculateCoverage(grand_count, grand_total);
    result.grand_total = {level8: 'Grand Total', count: grand_count, total: grand_total, coverage, is_covered};
    return result;
}

export function calculateLevel7Alignment(level7_coverage, level8_coverage, mappings) {

    const {level9_mappings, level8_namings, level6_namings} = mappings;

    const result = {data: []};
    const accmulated_level8 = {};

    for(const item of level8_coverage.data) {
        const {level9, level8, level6} = item;
        const found = level7_coverage.data.find(item => item.level8 === level8);
        const aligned =  found ? found.is_covered : false;
        if(accmulated_level8[level8]) continue;
        if(!level9_mappings[level9]) console.log('nav: missing level 9 -> ', level9);
        const {mb_no, dqc_no} = level9_mappings[level9];
        const {full_name: level8_full_name} = level8_namings[level8];
        const {full_name: level6_full_name} = level6_namings[level6]
        result.data.push({level8, level8_full_name, level6, level6_full_name, mb_no, dqc_no, aligned: aligned})
        accmulated_level8[level8] = true;
    }
    return result;
}

export function calculateLevel6Depth(level7_alignment, level8_coverage, mappings, assumption1 = 20, assumption2 = 3) {

    const {level8_mappings, level9_mappings} = mappings;

    const result = {data: [], assumption1, assumption2};

    //first we calculate the total occurrences of mb_no among all level8

    const all_mb = {}

    for(const level8 in level8_mappings) {
        const level9 = level8_mappings[level8][0]; //we assume that there is only one mb_no for each level 8
        if(level9) {
            const {mb_no} = level9_mappings[level9];
            if(!all_mb[mb_no]) all_mb[mb_no] = 1;
            else all_mb[mb_no] = all_mb[mb_no] + 1;
        }
    }

    //then we calculate the total occurrences of mb_no among given level8

    const given_mb = {};

    for(const item of level8_coverage.data) {
        const {level9, level8, matching, mb_no} = item;
        const {dqc_no} = level9_mappings[level9];

        const found = level7_alignment.data.find(item => item.mb_no === mb_no && item.level8 === level8);
        const aligned =  found ? found.aligned : false;

        if(!given_mb[mb_no]) given_mb[mb_no] = {level9_count: 0, level8_count: 0, level8_included: {}}; //declaration
        given_mb[mb_no].level9_count = given_mb[mb_no].level9_count + matching;
        if(!given_mb[mb_no].level8_included[level8] && aligned) {
            given_mb[mb_no].level8_count = given_mb[mb_no].level8_count + 1;
            given_mb[mb_no].level8_included[level8] = true;
        }
        given_mb[mb_no].dqc_no = dqc_no;
    }

    for(const mb_no in given_mb) {
        const {level9_count, level8_count, dqc_no} = given_mb[mb_no];
        const level8_total = all_mb[mb_no];
        const depth = Math.round(level8_total ? (level8_count/level8_total * 100) : 0);
        const is_covered = depth >= assumption1;
        const enough = level9_count >= assumption2;
        const pass = is_covered && enough ? "Pass" : "Mentioned";
        result.data.push({mb_no, level8_count, level8_total, depth, is_covered, level9_count, enough, pass, dqc_no});
    }
    return result;
}

export function calculateLevel6Breadth(level6_depth, mappings, assumption1 = 20, assumption2 = 1) {

    const {level9_mappings} = mappings;

    const result = {data: [], assumption1, assumption2};

    const pass_dqc = {};

    for(const item of level6_depth.data) {
        if(item.pass === "Pass") {
            const {dqc_no} = item;
            if(!pass_dqc[dqc_no]) pass_dqc[dqc_no] = {count: 0, related_mb: {}};
            pass_dqc[dqc_no].count = pass_dqc[dqc_no].count + 1;
        }
    }

    for(const item of Object.values(level9_mappings)) {
        const {mb_no, dqc_no} = item;
        if(pass_dqc[dqc_no]) {
            pass_dqc[dqc_no].related_mb[mb_no] = true;
        }
    }

    for(const dqc_no in pass_dqc) {
        const {count, related_mb} = pass_dqc[dqc_no];
        const total = Object.keys(related_mb).length;
        const breadth = Math.round(total ? (count/total * 100) : 0);
        const aligned = (count >= assumption2) * (breadth >= assumption1);
        result.data.push({dqc_no, count, total, breadth, aligned})
    }
    //grand total goes here
    return result;
}

export function calculateLevel6Enough(level8_coverage, mappings, assumption1 = 7) {

    const result = {data: [], assumption1};

    const given_dqc = {};

    for(const item of level8_coverage.data) {
        const {level9, matching} = item;
        const {dqc_no} = Level9ForwardMapping(mappings, level9);
        if(!given_dqc[dqc_no]) given_dqc[dqc_no] = 0;
        given_dqc[dqc_no] = given_dqc[dqc_no] + matching;
    }

    result.grand_total = {dqc_no: "Grand Total", count: 0};

    for(const dqc_no in given_dqc) {
        const count = given_dqc[dqc_no];
        result.grand_total.count = result.grand_total.count + count;
        result.data.push({dqc_no, count, enough: false}) //currently enough is false by default will be updated next
    }

    const total_dqcs = result.data.length;
    const grand_totals = result.grand_total.count;
    const percentage1_of_dqcs = total_dqcs/grand_totals;
    const percentage2_of_dqcs = percentage1_of_dqcs * 0.7; //fixed 70% discount rate
    const assumption = Math.ceil(percentage2_of_dqcs * grand_totals);

    result.data = result.data.map(item=> ({
        ...item,
        enough: item.count >= assumption
    }));
    result.assumption1 = assumption;

    return result;
}

export function calculateLevel5Analysis(level6_enough, mappings, assumption1 = 70) {

    const result = {data: [], assumption1};

    const total_dqc = level6_enough.data.length;
    const total = level6_enough.grand_total.count;

    result.grand_total = {dqc_no: "Grand Total", count: total, percent: 100}

    const unique_dqcs_count = (new Set(level6_enough.data)).size


    for(const item of level6_enough.data) {
        const {dqc_no, count} = item;
        const percent = total ? parseFloat((count/total * 100).toFixed(2)) : 0;
        const dqc_percent = total ? parseFloat((total_dqc/total * 100).toFixed(2)) : 0;
        const discount_percent = total ? parseFloat((total_dqc/total * assumption1).toFixed(2)) : 0;
        const discount_amount = total ? parseFloat((unique_dqcs_count / 100 * assumption1).toFixed(2)) : 0;
        result.data.push({dqc_no, count, percent, dqc_percent, discount_percent, discount_amount})
    }

    return result;
}