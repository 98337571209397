import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import {
    RouteWithLayout,
    PrivateRouteWithLayout,
    PreAuthRouteWithLayout
} from './components';

import {
    Main as MainLayout,
    Minimal as MinimalLayout
} from './layouts';

import {
    Login as LoginPage,
    Logout as LogoutPage,
    Register as RegisterPage,
    InitPasswordReset as InitPasswordResetPage,
    PasswordReset as PasswordResetPage,
    ChangePassword as ChangePasswordPage,
    ConfirmEmail as ConfirmEmailPage,
    Home as HomePage,
    NotFound as NotFoundPage,
    AppInfo as AppInfoPage,
    Overview as OverviewPage,
    Details as DetailsPage,
    EditAppCompany as EditAppCompanyPage,
    EditAppProgram as EditAppProgramPage,
    ListApplications as ListApplicationsPage,
    ListPayments as ListPaymentsPage,
    // ListAnalysis as ListAnalysisPage,
    AnalysisDetails as AnalysisDetailsPage,
    ListCertify as ListCertifyPage,
    DetailsCertify as DetailsCertifyPage
    // ReviewMapping as ReviewMappingPage
} from './pages';

const Routes = () => {
    return (
        <Switch>
            <Redirect
                exact
                from="/"
                to="/home"
            />
            <PreAuthRouteWithLayout
                component={LoginPage}
                exact
                layout={MinimalLayout}
                path="/login"
            />
            <PreAuthRouteWithLayout
                component={RegisterPage}
                exact
                layout={MinimalLayout}
                path="/register"
            />
            <PreAuthRouteWithLayout
                component={InitPasswordResetPage}
                exact
                layout={MinimalLayout}
                path="/account/recover"
            />
            <PreAuthRouteWithLayout
                component={PasswordResetPage}
                exact
                layout={MinimalLayout}
                no_redirect={true}
                path="/auth/reset/password"
            />

            <PrivateRouteWithLayout
                component={ConfirmEmailPage}
                exact
                layout={MinimalLayout}
                path="/auth/confirm/email"
            />
            <PrivateRouteWithLayout
                component={HomePage}
                exact
                layout={MainLayout}
                path="/home"
            />
            <PrivateRouteWithLayout
                component={OverviewPage}
                exact
                layout={MainLayout}
                path="/overview"
            />
            <PrivateRouteWithLayout
                component={ListApplicationsPage}
                exact
                layout={MainLayout}
                path="/application/list"
            />
            <PrivateRouteWithLayout
                component={DetailsPage}
                exact
                layout={MainLayout}
                path="/application/detail"
            />
            {/*<PrivateRouteWithLayout*/}
            {/*    component={Najeeb}*/}
            {/*    exact*/}
            {/*    layout={MainLayout}*/}
            {/*    path="/application/Najeeb"*/}
            {/*/>*/}
            <PrivateRouteWithLayout
                component={EditAppCompanyPage}
                exact
                layout={MainLayout}
                path="/application/edit/company"
            />
            <PrivateRouteWithLayout
                component={EditAppProgramPage}
                exact
                layout={MainLayout}
                path="/application/edit/program"
            />
            <PrivateRouteWithLayout
                component={ListPaymentsPage}
                exact
                layout={MainLayout}
                path="/payment/list"
            />
            {/*<PrivateRouteWithLayout*/}
            {/*    component={ListAnalysisPage}*/}
            {/*    exact*/}
            {/*    layout={MainLayout}*/}
            {/*    path="/analysis/list"*/}
            {/*/>*/}
            <PrivateRouteWithLayout
                component={ListCertifyPage}
                exact
                layout={MainLayout}
                path="/certify/list"
            />
            <PrivateRouteWithLayout
                component={DetailsCertifyPage}
                exact
                layout={MainLayout}
                path="/certify/detail"
            />
            <PrivateRouteWithLayout
                component={AnalysisDetailsPage}
                exact
                layout={MainLayout}
                path="/analysis/detail"
            />
            {/*<PrivateRouteWithLayout*/}
            {/*    component={ReviewMappingPage}*/}
            {/*    exact*/}
            {/*    layout={MainLayout}*/}
            {/*    path="/application/mapping/review"*/}
            {/*/>*/}
            <PrivateRouteWithLayout
                component={ChangePasswordPage}
                exact
                layout={MainLayout}
                path="/password/change"
            />
            <PrivateRouteWithLayout
                component={LogoutPage}
                exact
                layout={MainLayout}
                path="/logout"
            />



            {/*########################*/}
            {/*### Miscellaneous -- ROUTES ###*/}
            {/*########################*/}

            <RouteWithLayout
                component={AppInfoPage}
                exact={false}
                layout={MinimalLayout}
                path="/app_info"
            />

            <RouteWithLayout
                component={NotFoundPage}
                exact={false}
                layout={MinimalLayout}
                path="/"
            />

        </Switch>
    );
};

export default Routes;
