import React, {useEffect, useState} from 'react';
import {
    Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Paper,
    Box, TablePagination, Checkbox
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {SEAL_TYPE_VERBOSE, APP_STATUS, APP_STATUS_VERBOSE} from "../../utils/constants";
import {getAppsService} from "../../services/app.service";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import {useHistory} from "react-router-dom";
import AnalysisTableRow from './components/AnalysisTableRow.comp';
import AllAnalysisChartsData from './components/AllAnalysisChartsData.comp';
import LoaderWithBackDrop from "../../components/LoaderWithBackDrop/LoaderWithBackDrop.comp";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4),
    },
    paper: {
        position: 'absolute',
        width: '80%',
        maxHeight: '80%',
        overflowY: 'auto',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'underline',
    },
}));

const date_options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

function getFieldValue(field, value) {
    if(value === null || value === undefined) return "";
    switch(field) {
        case "submission_time":
            return (new Date(value)).toLocaleDateString(undefined, date_options);
        case "dqseal_type":
            return SEAL_TYPE_VERBOSE[value];
        case "app_status":
            return APP_STATUS_VERBOSE[value];
        default:
            return value;
    }
}

function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const ListAnalysis = () => {
    const classes = useStyles();
    const [appData, setAppData] = useState([]);

    const history = useHistory();
    const [loading, setLoading] = useState(false);


    //Pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchText, setSearchText] = useState('');

    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);


    function setAppDataEx(data) {
        setAppData(data);
        setSearchText(searchText);
    }

    const searchRegex = new RegExp(escapeRegExp(searchText), 'i');

    const field_filter_function = (row) => {
        return Object.keys(row).some((field) => {
            const value = getFieldValue(field, row[field]);
            return searchRegex.test(value.toString());
        });
    }
    const filteredRows = appData.filter(field_filter_function).filter((row) => {
        const status = row['app_status'];
        return status === APP_STATUS.COMPLETED || status === APP_STATUS.PENDING_REVIEW_REPORT || status === APP_STATUS.HALTED_REPORT;
      });

    const handleChangePage = (event, newPage) => setPage(newPage);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    async function fetchApplications() {
        try {
            setLoading(true);
          const result_apps = await getAppsService();
          if (!result_apps.error) {
            const applications = result_apps.data;
            setAppDataEx(applications.filter(Boolean)); // Filter out any undefined values
          }
          setLoading(false);

        } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(false);
          // Handle the error
        }

      }

    useEffect(() => {
        fetchApplications().then((res) => `fetch initiated, ${res}`);
    }, [])

    const viewAnalysis = (e, row) => {
        history.push({pathname: '/analysis/detail', state: row})
    }

    const handleRowSelect = (row) => {
        if (selectAll) {
          setSelectedRows((prevSelectedRows) =>
            prevSelectedRows.filter((selectedRow) => selectedRow.application_id !== row.application_id)
          );
        } else {
          setSelectedRows((prevSelectedRows) => {
            const isSelected = prevSelectedRows.some((selectedRow) => selectedRow.application_id === row.application_id);
            if (isSelected) {
              return prevSelectedRows.filter((selectedRow) => selectedRow.application_id !== row.application_id);
            } else {
              return [...prevSelectedRows, row];
            }
          });
        }
      };
      

      const handleSelectAllChange = (event) => {
        const checked = event.target.checked;

        setSelectAll(checked);
      
        if (checked) {
          setSelectedRows([...filteredRows]);
        } else {
          setSelectedRows([]);
        }
      };
      
    return (
        <Container maxWidth={false}>
            <Box>
            <AllAnalysisChartsData applications={selectedRows.length > 0 ? selectedRows : filteredRows} />
            </Box>
            <Box display={'flex'} justifyContent={'flex-end'} mb={2}>
                <TextField
                    variant="outlined"
                    value={searchText}
                    onChange={(event) => setSearchText(event.target.value)}
                    placeholder="Search…"
                    className={classes.textField}
                    InputProps={{
                        startAdornment: <SearchIcon fontSize="small" />,
                        endAdornment: (
                            <IconButton
                                title="Clear"
                                aria-label="Clear"
                                size="small"
                                style={{ visibility: searchText ? 'visible' : 'hidden' }}
                                onClick={() => setSearchText('')}
                            >
                                <ClearIcon fontSize="small" />
                            </IconButton>
                        ),
                    }}
                />
            </Box>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Checkbox 
                                checked={selectAll} 
                                onChange={handleSelectAllChange}
                                color='primary' />
                            </TableCell>
                            <TableCell>ID</TableCell>
                            <TableCell>Program</TableCell>
                            <TableCell>Submission Time</TableCell>
                            <TableCell>Powered by DQ Package</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Accuracy</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => (
                                <AnalysisTableRow key={row.application_id} 
                                row={row} 
                                selectedRows={selectedRows}
                                onSelect={handleRowSelect}
                                viewAnalysis={viewAnalysis} 
                                />
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        <LoaderWithBackDrop loading={loading}/>
        </Container>
    );
};

export default ListAnalysis;
